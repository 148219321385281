<template>
  <div class="set">
    <div class="cont">
      <van-cell title="清除缓存" is-link @click="tips" />
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { removeOpenId } from "@/utils/auth";

export default {
  name: "set",

  data() {
    return {};
  },
  created() {},
  methods: {
    tips() {
      Dialog.confirm({
        title: "确认清除缓存",
        // message: "弹窗内容"
      })
        .then(() => {
          // on confirm
          removeOpenId();
          sessionStorage.removeItem("JSSDK");
          this.Toast("清除成功");
          window.location.search = "";
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.set {
  background: #f5f5f5;
  position: absolute;
  top: 46px;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 500px;
  margin: 0 auto;
  .cont {
    padding: 20px 0;
  }
}
</style>
